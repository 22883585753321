import { Head } from '@inertiajs/react';
import { ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Campaign, CampaignType, Event, Project } from '../../Shared/types';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import AddCampaignForm from '../Campaign/AddCampaignForm';
import EventHeader from '../Event/EventHeader';
import { useRouter } from '../router';
import { SharedProps } from '../types';
import Breadcrumb, { breadcrumbClasses } from '../UI/Breadcrumb';
import Container from '../UI/Container';
import { EmbedContext } from '../UI/EmbedProvider';
import Header from '../UI/Header';
import Layout from '../UI/Layout';
import { NavBar } from '../UI/NavBar';

export interface CreateCampaignPageProps extends SharedProps {
  project: Project | null;
  event: Event | null;
  team: Campaign | null;
  teams: Campaign[];
  redirect?: (url: string) => void;
}

export default function CreateCampaignPage({
  project,
  event,
  team,
  teams,
  charity,
  auth: {
    user,
  },
  params,
}: CreateCampaignPageProps) {
  const { t } = useTranslation();
  const { routes } = useRouter();
  const { isEmbedded } = useContext(EmbedContext);

  useCharityStyle(charity);

  const memberId = typeof params.member_id === 'string' ? params.member_id : null;
  const type = params.type === CampaignType.Individual || params.type === CampaignType.Team ? params.type : null;

  return (
    <>
      <Head title={`${charity.title}`} />

      <NavBar charity={charity} user={user} showLogin={false} />

      <Header>
        <Container>
          {!team && !project && event && (
            <Breadcrumb href={params.return_url || routes.event_page(event.id)}>
              {event.title}
            </Breadcrumb>
          )}

          {!team && !isEmbedded && project?.visible && (
            <Breadcrumb href={params.return_url || routes.project_page(project.id)}>
              {project.title}
            </Breadcrumb>
          )}

          {!team && project && (isEmbedded || !project.visible) && (
            <div className={breadcrumbClasses}>
              {project.title}
            </div>
          )}

          {team && (
            <Breadcrumb href={params.return_url || routes.campaign_page(team.id)}>
              {team.title}
            </Breadcrumb>
          )}

          <h2 className="text-xl">
            {team
              ? t('frontend:join_team_name', { team: team.title })
              : t('frontend:start_campaign_for_charity', { charity: charity.title })}
          </h2>

          {event && (
            <>
              <hr className="border-slate-200" />
              <EventHeader event={event} />
            </>
          )}
        </Container>
      </Header>

      <Container>
        {(!project || (project.open && !project.ended)) && (
          <AddCampaignForm
            project={project}
            event={event}
            type={type}
            team={team}
            teams={teams}
            memberId={memberId}
            charity={charity}
            user={user}
          />
        )}

        {project && (!project.open || project.ended) && (
          <div className="text-slate-500">
            {t('frontend:project_closed_message')}
          </div>
        )}
      </Container>
    </>
  );
}

CreateCampaignPage.layout = (page: ReactNode) => <Layout>{page}</Layout>;
