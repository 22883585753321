import { ChevronRight } from 'react-feather';

import { Charity, Event } from '../../Shared/types';
import { Card } from '../../Shared/UI/Card';
import { Icon } from '../../Shared/UI/Icon';
import { useRouter } from '../router';

export default function CharityCard({
  charity,
  event,
}: {
  charity: Charity;
  event: Event,
}) {
  const { routes } = useRouter();

  return (
    <Card
      spacing="xs"
      link={{
        href: `${routes.create_campaign_page(charity.id)}?event_id=${event.id}&return_url=${encodeURIComponent(routes.event_page(event.id))}`,
        ariaLabel: charity.title,
      }}
      border
      className="flex items-center gap-4"
    >
      <div className="flex items-center justify-center flex-shrink-0 w-16 h-16 p-2 border rounded">
        {charity.logo_url && (
          <img src={charity.logo_url} alt={charity.title} className="block object-contain w-full h-full" />
        )}
        {!charity.logo_url && (
          <span className="block w-10 h-10 rounded-full leading-[2.5rem] text-center bg-blue-300 text-white">
            {charity.title.slice(0, 1)}
          </span>
        )}
      </div>
      <div className="min-w-0">
        <div className="flex items-center gap-2">
          <h4 className="overflow-hidden whitespace-nowrap text-ellipsis">
            {charity.title}
          </h4>
          <Icon className="text-slate-400">
            <ChevronRight />
          </Icon>
        </div>
        <div className="w-full text-slate-500 line-clamp-2">
          {charity.description}
        </div>
      </div>
    </Card>
  );
}
