import { usePage } from '@inertiajs/react';
import Vapor from 'laravel-vapor';
import { ChangeEvent, useState } from 'react';
import { ChevronRight, Image, Trash, User as UserIcon, UserPlus, Users } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { useMutation, useServerErrors } from '../../Shared/api';
import ImageCropper from '../../Shared/Image/ImageCropper';
import { useLocale } from '../../Shared/locale';
import { Campaign, CampaignType, Charity, Event, Project, User } from '../../Shared/types';
import AddressInputs from '../../Shared/UI/AddressInputs';
import { Checkbox } from '../../Shared/UI/Checkbox';
import CheckboxLabel from '../../Shared/UI/CheckboxLabel';
import ErrorMessage from '../../Shared/UI/ErrorMessage';
import { FadeIn } from '../../Shared/UI/FadeIn';
import { FileInput } from '../../Shared/UI/FileInput';
import { Icon } from '../../Shared/UI/Icon';
import { Input } from '../../Shared/UI/Input';
import InputDescription from '../../Shared/UI/InputDescription';
import InputGroup from '../../Shared/UI/InputGroup';
import InputLabel from '../../Shared/UI/InputLabel';
import { InputWrapper } from '../../Shared/UI/InputWrapper';
import PhoneNumberInput from '../../Shared/UI/PhoneNumberInput';
import { Radio } from '../../Shared/UI/Radio';
import RadioLabel from '../../Shared/UI/RadioLabel';
import { SearchInput } from '../../Shared/UI/SearchInput';
import ServerErrors from '../../Shared/UI/ServerErrors';
import { Textarea } from '../../Shared/UI/Textarea';
import { useCurrencyInput } from '../../Shared/useCurrencyInput';
import { useDateInput } from '../../Shared/useDateInput';
import { useRouter } from '../router';
import { PageProps } from '../types';
import { Button } from '../UI/Button';

type TeamMode = 'create_individual_campaign' | 'join_team_campaign' | 'create_team_campaign';

export interface AddCampaignFormProps {
  project?: Project | null;
  event?: Event | null;
  type?: CampaignType | null;
  team?: Campaign | null;
  teams: Campaign[];
  memberId?: string | null;
  charity: Charity;
  user?: User | null;
}

export default function AddCampaignForm({
  project,
  event,
  type = null,
  team,
  teams,
  memberId,
  charity,
  user,
}: AddCampaignFormProps) {
  const { t } = useTranslation();
  const { handleCurrencyBlur, parseCurrency } = useCurrencyInput();
  const { handleDateBlur, parseDate, formatDate } = useDateInput();
  const { defaultCountry } = useLocale();
  const { routes } = useRouter();
  const { params } = usePage<PageProps>().props;

  const defaultCampaignType = project
    ? (type && project.allowed_campaign_types.includes(type) ? type : project.default_campaign_type)
    : CampaignType.Individual;

  const form = useForm({
    defaultValues: {
      team_mode: team
        ? 'join_team_campaign'
        : (memberId
          ? 'create_team_campaign'
          : (defaultCampaignType === CampaignType.Individual ? 'create_individual_campaign' : undefined)
        ) as TeamMode,
      team_id: team?.id || null,
      title: '',
      image: null,
      start_date: (team?.start_date ? formatDate(team.start_date) : null) || '',
      end_date: (team?.end_date ? formatDate(team.end_date) : null) || '',
      fundraising_target: '',
      description: '',
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
      email: user?.email,
      marketing_email_opt_in: false,
      date_of_birth: '',
      company_name: '',
      phone: '',
      street: '',
      house_number: '',
      extra_address_line: '',
      zip_code: '',
      city: '',
      country: defaultCountry,
      agree_to_terms: false,
    },
  });

  const {
    register, control, handleSubmit, setError,
    formState: { errors, defaultValues }, watch, resetField, setValue,
  } = form;

  const teamMode = watch('team_mode');

  const allowIndividuals = !project || project.allow_individuals;
  const allowJoinTeam = project?.allow_teams;
  const allowCreateTeam = !project || project.allow_teams;
  const showCampaignType = [allowIndividuals, allowJoinTeam, allowCreateTeam].filter((allow) => allow).length > 1 && !memberId;

  const joinTeam = teamMode === 'join_team_campaign';
  const [selectedTeam, setSelectedTeam] = useState(team);

  // Disable some fields for team members
  const projectFields = (project?.fields || []).filter(
    (field) => !joinTeam || !['date', 'company_name'].includes(field)
  );

  const [image, setImage] = useState<File>();
  const [croppedImage, setCroppedImage] = useState<File>();

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setImage(event.target.files?.[0] || undefined);
    setCroppedImage(undefined);
    resetField('image'); // Reset errors
  };

  const handleCropChange = (file: File, element: HTMLImageElement) => {
    if (element.naturalWidth < 800 || element.naturalHeight < 450) {
      resetImage();
      setError('image', { message: t('shared:validation.image_too_small') });
    } else {
      setCroppedImage(file);
    }
  };

  const resetImage = () => {
    setImage(undefined);
    setCroppedImage(undefined);
    resetField('image'); // Reset errors
  };

  const startDate = watch('start_date');

  const [isCompany, setIsCompany] = useState(project?.required_fields.includes('company_name') || false);
  const toggleCompany = () => setIsCompany((isCompany) => !isCompany);

  const [addCampaign, { loading, errors: serverErrors }] = useMutation(
    routes.add_campaign(),
  );

  useServerErrors(form, serverErrors);

  const submit = async (data: typeof defaultValues = {}) => {
    await addCampaign({
      project_id: project?.id,
      event_id: event?.id,
      type: data?.team_mode === 'create_team_campaign' ? CampaignType.Team : CampaignType.Individual,
      team_id: data?.team_mode === 'join_team_campaign' ? data?.team_id : null,
      member_id: memberId,
      title: data?.title,
      image: image && croppedImage ? (await Vapor.store(croppedImage))?.key || null : null,
      start_date: data?.start_date ? parseDate(data.start_date) : null,
      end_date: data?.end_date ? parseDate(data.end_date) : null,
      fundraising_target: data?.fundraising_target ? parseCurrency(data.fundraising_target) : null,
      description: data?.description,
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      marketing_email_opt_in: data?.marketing_email_opt_in,
      date_of_birth: data?.date_of_birth ? parseDate(data.date_of_birth) : null,
      company_name: isCompany ? data?.company_name : null,
      phone: data?.phone,
      street: data?.street,
      house_number: data?.house_number,
      extra_address_line: data?.extra_address_line,
      zip_code: data?.zip_code,
      city: data?.city,
      country: data?.country,
    });
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="space-y-6">
        {serverErrors && (
          <div>
            <ServerErrors errors={serverErrors} defaultValues={defaultValues} scrollIntoView />
          </div>
        )}

        <div className="space-y-6">
          <h4 className="font-medium text-slate-500">
            {t('frontend:campaign_details')}
          </h4>

          {showCampaignType && (
            <InputGroup>
              <InputLabel valid required>
                {t('frontend:campaign_type')}
              </InputLabel>
              {allowIndividuals && (
                <RadioLabel className="border border-slate-250 rounded-md px-3 py-2 relative overflow-hidden">
                  <Radio
                    {...register('team_mode')}
                    value="create_individual_campaign"
                    aria-invalid={!!errors.team_mode}
                  />
                  <div className="leading-snug pr-[80px]">
                    <div className="font-medium">
                      {t('frontend:create_individual_campaign')}
                    </div>
                    <div className="text-slate-500">
                      {t('frontend:create_individual_campaign_description')}
                    </div>
                  </div>
                  <div className="rounded-l-full w-[100px] h-[160px] bg-project/10 text-project flex items-center justify-center absolute -right-6 pr-5 top-1/2 -mt-[80px] text-2xl">
                    <Icon>
                      <UserIcon strokeWidth={1.5} />
                    </Icon>
                  </div>
                </RadioLabel>
              )}
              {allowJoinTeam && (
                <RadioLabel className="border border-slate-250 rounded-md px-3 py-2 mt-2 relative overflow-hidden">
                  <Radio
                    {...register('team_mode')}
                    value="join_team_campaign"
                    aria-invalid={!!errors.team_mode}
                  />
                  <div className="leading-snug pr-[80px]">
                    <div className="font-medium">
                      {t('frontend:join_team_campaign')}
                    </div>
                    <div className="text-slate-500">
                      {t('frontend:join_team_campaign_description')}
                    </div>
                  </div>
                  <div className="rounded-l-full w-[100px] h-[160px] bg-project/10 text-project flex items-center justify-center absolute -right-6 pr-5 top-1/2 -mt-[80px] text-2xl">
                    <Icon>
                      <UserPlus strokeWidth={1.5} />
                    </Icon>
                  </div>
                </RadioLabel>
              )}
              {allowCreateTeam && (
                <RadioLabel className="border border-slate-250 rounded-md px-3 py-2 mt-2 relative overflow-hidden">
                  <Radio
                    {...register('team_mode')}
                    value="create_team_campaign"
                    aria-invalid={!!errors.team_mode}
                  />
                  <div className="leading-snug pr-[80px]">
                    <div className="font-medium">
                      {t('frontend:create_team_campaign')}
                    </div>
                    <div className="text-slate-500">
                      {t('frontend:create_team_campaign_description')}
                    </div>
                  </div>
                  <div className="rounded-l-full w-[100px] h-[160px] bg-project/10 text-project flex items-center justify-center absolute -right-6 pr-5 top-1/2 -mt-[80px] text-2xl">
                    <Icon>
                      <Users strokeWidth={1.5} />
                    </Icon>
                  </div>
                </RadioLabel>
              )}
              <ErrorMessage attribute={t('frontend:campaign_type')} error={errors.team_mode} />
            </InputGroup>
          )}

          <FadeIn
            when={joinTeam}
            render={() => (
              <InputGroup>
                <InputLabel required valid htmlFor="add_campaign_team">
                  {t('frontend:team')}
                </InputLabel>
                {!selectedTeam && (
                  <>
                    <InputDescription>
                      {t('frontend:find_team_description')}
                    </InputDescription>
                    <SearchInput name="team" id="add_campaign_team" />
                    {teams.length > 0 && (
                      <div className="space-y-1 mt-3">
                        {teams.map((team) => (
                          <RadioLabel className="border border-slate-150 hover:border-slate-250 rounded-md px-3 py-2" key={team.id}>
                            <Radio
                              {...register('team_id', {
                                onChange: (event) => setSelectedTeam(teams.find(team => team.id === event.target.value) || null),
                              })}
                              value={team.id}
                            />
                            <div className="flex space-x-2 items-center">
                              <div className="w-8 h-6 rounded bg-project/10 flex items-center justify-center">
                                {team.thumbnail_url && (
                                  <img src={team.thumbnail_url} className="block object-cover w-full h-full rounded" />
                                )}
                                {!team.thumbnail_url && charity.logo_url && (
                                  <img src={charity.logo_url} className="block w-1/4 opacity-50" />
                                )}
                              </div>
                              <span>
                                {team.title}
                              </span>
                            </div>
                          </RadioLabel>
                        ))}
                      </div>
                    )}
                    {!!params.team_search && teams.length === 0 && (
                      <div className="text-slate-500 mt-2">
                        {t('frontend:no_results')}
                      </div>
                    )}
                  </>
                )}
                {selectedTeam && (
                  <CheckboxLabel className="border border-slate-250 rounded-md px-3 py-2">
                    <Checkbox {...register('team_id', { onChange: () => setSelectedTeam(null) })} />
                    <div className="flex space-x-2 items-center">
                      <div className="w-8 h-6 rounded bg-project/10 flex items-center justify-center">
                        {selectedTeam.thumbnail_url && (
                          <img src={selectedTeam.thumbnail_url} className="block object-cover w-full h-full rounded" />
                        )}
                        {!selectedTeam.thumbnail_url && charity.logo_url && (
                          <img src={charity.logo_url} className="block w-1/4 opacity-50" />
                        )}
                      </div>
                      <span>
                        {selectedTeam.title}
                      </span>
                    </div>
                  </CheckboxLabel>
                )}
              </InputGroup>
            )}
          />

          <FadeIn
            when={!joinTeam}
            render={() => (
              <InputGroup>
                <InputLabel required valid={!errors.title} htmlFor="add_campaign_title">
                  {t('frontend:campaign_title')}
                </InputLabel>
                <InputDescription>
                  {t('frontend:campaign_title_description')}
                </InputDescription>
                <Input
                  {...register('title', { required: true })}
                  id="add_campaign_title"
                  aria-invalid={!!errors.title}
                />
                <ErrorMessage attribute={t('frontend:campaign_title')} error={errors.title }/>
              </InputGroup>
            )}
          />

          <InputGroup>
            <InputLabel htmlFor="add_campaign_image">
              {t('frontend:campaign_image')}
            </InputLabel>
            <InputDescription>
              {t('frontend:campaign_image_description')}
            </InputDescription>
            <div className="p-4 border rounded-md border-slate-250">
              <div
                className="flex items-center justify-center mb-4 transition-all rounded-sm bg-slate-50 aspect-video"
              >
                {image && (
                  <ImageCropper
                    image={image}
                    onChange={handleCropChange}
                    aspect={16 / 9}
                  />
                )}
                {!image && (
                  <Icon className="!w-12 !h-12 text-slate-250">
                    <Image strokeWidth={1} />
                  </Icon>
                )}
              </div>
              <div className="flex space-x-2">
                <FileInput
                  {...register('image')}
                  accept=".jpg,.jpeg,.png"
                  id="add_campaign_image"
                  aria-invalid={!!errors.image}
                  onChange={handleImageChange}
                >
                  <Icon className="mr-2">
                    <Image />
                  </Icon>
                  {t('shared:form.select')}
                </FileInput>
                {image && (
                  <Button onClick={() => resetImage()} variant="tertiary" size="sm">
                    <Icon className="mr-2">
                      <Trash />
                    </Icon>
                    {t('shared:form.delete')}
                  </Button>
                )}
              </div>
            </div>
            <ErrorMessage error={errors.image} attribute={t('frontend:campaign_image')} />
          </InputGroup>

          {project && projectFields.includes('date') && (
            <>
              <InputGroup>
                <InputLabel
                  required={project.required_fields.includes('date')}
                  valid={!errors.start_date}
                  htmlFor="add_campaign_start_date"
                >
                  {t('frontend:start_date')}
                </InputLabel>
                <InputDescription>
                  {t('frontend:campaign_start_date_description')}
                </InputDescription>
                <Input
                  {...register('start_date', { required: project.required_fields.includes('date') })}
                  onBlur={(event) => {
                    handleDateBlur(event);
                    setValue('start_date', event.target.value);
                  }}
                  id="add_campaign_start_date"
                  placeholder={t('shared:date_input.date_format_placeholder')}
                  aria-invalid={!!errors.start_date}
                />
                <ErrorMessage error={errors.start_date} attribute={t('frontend:start_date')} />
              </InputGroup>
              <InputGroup>
                <InputLabel valid={!errors.end_date} htmlFor="add_campaign_end_date">
                  {t('frontend:end_date')}
                </InputLabel>
                <InputDescription>
                  {t('frontend:campaign_end_date_description')}
                </InputDescription>
                <Input
                  {...register('end_date')}
                  onBlur={(event) => {
                    handleDateBlur(event);
                    setValue('end_date', event.target.value);
                  }}
                  id="add_campaign_end_date"
                  placeholder={startDate || t('shared:date_input.date_format_placeholder')}
                  aria-invalid={!!errors.end_date}
                />
                <ErrorMessage error={errors.end_date} attribute={t('frontend:date')} />
              </InputGroup>
            </>
          )}

          {project && projectFields.includes('fundraising_target') && (
            <InputGroup>
              <InputLabel
                required={project.required_fields.includes('fundraising_target')}
                valid={!errors.fundraising_target}
                htmlFor="add_campaign_fundraising_target"
              >
                {t('frontend:fundraising_target')}
              </InputLabel>
              <InputDescription>
                {t('frontend:fundraising_target_description', { charity: charity.title })}
              </InputDescription>
              <InputWrapper>
                <label htmlFor="add_campaign_fundraising_target">
                  €
                </label>
                <Input
                  {...register('fundraising_target', {
                    required: project.required_fields.includes('fundraising_target'),
                  })}
                  id="add_campaign_fundraising_target"
                  className="pl-7"
                  onBlur={(event) => {
                    handleCurrencyBlur(event);
                    setValue('fundraising_target', event.target.value);
                  }}
                  aria-invalid={!!errors.fundraising_target}
                  min="0"
                />
              </InputWrapper>
              <ErrorMessage error={errors.fundraising_target} attribute={t('frontend:fundraising_target')} />
            </InputGroup>
          )}

          <InputGroup>
            <InputLabel htmlFor="add_campaign_description">
              {t('frontend:campaign_description')}
            </InputLabel>
            <InputDescription>
              {t('frontend:campaign_description_description')}
            </InputDescription>
            <Textarea
              {...register('description')}
              id="add_campaign_description"
              aria-invalid={!!errors.description}
            />
            <ErrorMessage error={errors.description} attribute={t('frontend:campaign_description')} />
          </InputGroup>

          <hr className="border-slate-200" />

          <h4 className="font-medium text-slate-500">
            {t('frontend:your_details')}
          </h4>

          <div className="grid grid-cols-2 gap-6">
            <InputGroup>
              <InputLabel required valid={!errors.first_name} htmlFor="add_campaign_first_name">
                {t('frontend:first_name')}
              </InputLabel>
              <Input
                {...register('first_name', { required: true })}
                id="add_campaign_first_name"
                aria-invalid={!!errors.first_name}
              />
              <ErrorMessage attribute={t('frontend:first_name')} error={errors.first_name }/>
            </InputGroup>

            <InputGroup>
              <InputLabel required valid={!errors.last_name} htmlFor="add_campaign_last_name">
                {t('frontend:last_name')}
              </InputLabel>
              <Input
                {...register('last_name', { required: true })}
                id="add_campaign_last_name"
                aria-invalid={!!errors.last_name}
              />
              <ErrorMessage attribute={t('frontend:last_name')} error={errors.last_name }/>
            </InputGroup>
          </div>

          <InputGroup>
            <InputLabel required valid={!errors.email} htmlFor="add_campaign_email">
              {t('frontend:email_address')}
            </InputLabel>
            <Input
              {...register('email', { required: true })} inputMode="email"
              id="add_campaign_email"
              aria-invalid={!!errors.email}
            />
            <ErrorMessage attribute={t('frontend:email_address')} error={errors.email }/>
          </InputGroup>

          {project && projectFields.includes('date_of_birth') && (
            <InputGroup>
              <InputLabel
                required={project.required_fields.includes('date_of_birth')}
                valid={!errors.date_of_birth}
                htmlFor="add_campaign_date_of_birth"
              >
                {t('frontend:date_of_birth')}
              </InputLabel>
              <Input
                {...register('date_of_birth', { required: project.required_fields.includes('date_of_birth') })}
                onBlur={(event) => {
                  handleDateBlur(event);
                  setValue('date_of_birth', event.target.value);
                }}
                id="add_campaign_date_of_birth"
                placeholder={t('shared:date_input.date_format_placeholder')}
                aria-invalid={!!errors.date_of_birth}
              />
              <ErrorMessage error={errors.date_of_birth} attribute={t('frontend:date_of_birth')} />
            </InputGroup>
          )}

          {project && projectFields.includes('company_name') && (
            <>
              {!project.required_fields.includes('company_name') && (
                <InputGroup>
                  <CheckboxLabel>
                    <Checkbox
                      checked={isCompany}
                      onChange={toggleCompany}
                    />
                    <span>
                      {t('frontend:create_company_campaign')}
                    </span>
                  </CheckboxLabel>
                </InputGroup>
              )}
              <FadeIn
                when={isCompany}
                render={() => (
                  <InputGroup>
                    <InputLabel
                      required={isCompany}
                      valid={!errors.company_name}
                      htmlFor="add_campaign_company_name"
                    >
                      {t('frontend:company_name')}
                    </InputLabel>
                    <Input
                      {...register('company_name', {
                        required: isCompany,
                      })}
                      id="add_campaign_company_name"
                      aria-invalid={!!errors.company_name}
                    />
                    <ErrorMessage error={errors.company_name} attribute={t('frontend:company_name')} />
                  </InputGroup>
                )}
              />
            </>
          )}

          {project && projectFields.includes('phone') && (
            <InputGroup>
              <InputLabel
                required={project.required_fields.includes('phone')}
                valid={!errors.phone}
                htmlFor="add_campaign_phone"
              >
                {t('frontend:phone_number')}
              </InputLabel>
              <Controller
                control={control}
                name="phone"
                rules={{
                  required: project.required_fields.includes('phone'),
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <PhoneNumberInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    id="add_campaign_phone"
                    ariaInvalid={!!errors.phone}
                  />
                )}
              />
              <ErrorMessage error={errors.phone} attribute={t('frontend:phone_number')} />
            </InputGroup>
          )}

          {project && projectFields.includes('address') && (
            <AddressInputs form={form} required={project.required_fields.includes('address')} />
          )}

          <CheckboxLabel>
            <Checkbox {...register('marketing_email_opt_in')}/>
            <span>
              {t('frontend:email_opt_in_text', { charity: charity.title })}
            </span>
          </CheckboxLabel>

          {project?.terms_url && (
            <InputGroup>
              <CheckboxLabel>
                <Checkbox
                  {...register('agree_to_terms', { required: true })}
                  aria-invalid={!!errors.agree_to_terms}
                />
                <span>
                  <Trans i18nKey="frontend:agree_to_terms" values={{ url: project.terms_url }}>
                    <a href={project.terms_url || ''} target="_blank" rel="noopener noreferrer" />
                  </Trans>
                </span>
              </CheckboxLabel>
              <ErrorMessage
                error={errors.agree_to_terms}
                message={errors.agree_to_terms ? t('frontend:agree_to_terms_to_continue') : null}
              />
            </InputGroup>
          )}

          <hr className="border-slate-200" />

          <Button type="submit" size="lg" className="w-full" disabled={loading}>
            {t('frontend:create_campaign')}
            <Icon className="ml-2">
              <ChevronRight />
            </Icon>
          </Button>
        </div>
      </div>
    </form>
  );
}
