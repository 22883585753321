import { Head } from '@inertiajs/react';
import { ReactNode } from 'react';
import { ChevronRight, ExternalLink, HelpCircle, User } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { Charity, Event, Pagination, SharedProps } from '../../Shared/types';
import { Card } from '../../Shared/UI/Card';
import { Icon } from '../../Shared/UI/Icon';
import { Pager } from '../../Shared/UI/Pager';
import { SearchInput } from '../../Shared/UI/SearchInput';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import EventHeader from '../Event/EventHeader';
import { useRouter } from '../router';
import Container from '../UI/Container';
import Header from '../UI/Header';
import Layout from '../UI/Layout';
import { NavBar } from '../UI/NavBar';

export interface SelectCharityPageProps extends SharedProps {
  charity: Charity | null;
  event: Event | null;
  charities: Pagination<Charity>;
  events: Pagination<Event>;
}

export default function SelectCharityPage({
  charity,
  event,
  charities,
  events,
  auth: {
    user,
  },
  params,
}: SelectCharityPageProps) {
  const { t } = useTranslation();
  const { routes } = useRouter();

  useCharityStyle(charity);

  return (
    <>
      <Head title={t('frontend:start_a_campaign')} />

      <NavBar user={user} />

      {charity && (
        <Header>
          <Container>
            <div className="flex items-center space-x-4">
              {charity.logo_url && (
                <div className="shrink-0">
                  <div className="w-[100px] h-[100px] shadow bg-white border rounded-full flex items-center justify-center">
                    <img src={charity.logo_url} className="block max-h-[84px] max-w-[84px] rounded-full" />
                  </div>
                </div>
              )}
              <div>
                <h2 className="text-lg">
                  {charity.title}
                </h2>
              </div>
            </div>
          </Container>
        </Header>
      )}

      {event && (
        <Header>
          <Container>
            <EventHeader event={event} />
          </Container>
        </Header>
      )}

      <Container>
        {!charity && (
          <div className="space-y-4">
            <div>
              <h2 className="text-lg">
                {t('frontend:start_campaign_with_supporta')}
              </h2>
              <p>
                {t('frontend:select_charity_description')}
              </p>
            </div>

            {(params.search || charities.meta.last_page > 1) && (
              <div>
                <SearchInput id="search" placeholder={t('frontend:find_your_charity')} autoFocus />
              </div>
            )}

            <div className="space-y-2">
              {charities.data.map((charity) => (
                <CharityCard charity={charity} event={event} key={charity.id} />
              ))}
            </div>

            {charities.data.length === 0 && (
              <div className="text-slate-500">
                {t('frontend:no_results')}
              </div>
            )}

            {charities.meta.last_page > 1 && (
              <Pager data={charities} />
            )}
          </div>
        )}

        {charity && (
          <div className="space-y-4">
            <div>
              <h2 className="text-lg">
                {t('frontend:start_campaign_with_supporta')}
              </h2>
              <p>
                {t('frontend:create_fundraiser_description')}
              </p>
            </div>

            {(params.search || events.meta.last_page > 1) && (
              <div>
                <SearchInput id="search" placeholder={t('frontend:find_your_event')} autoFocus />
              </div>
            )}

            <div>
              <a href={routes.select_charity_page({ charityId: charity.id, eventId: 'default' })}>
                {t('frontend:continue_without_event')}
              </a>
            </div>

            <div className="space-y-2">
              {events.data.map((event) => (
                <EventCard charity={charity} event={event} key={event.id} />
              ))}
            </div>

            {events.data.length === 0 && (
              <div className="text-slate-500">
                {t('frontend:no_results')}
              </div>
            )}

            {events.meta.last_page > 1 && (
              <Pager data={events} />
            )}
          </div>
        )}

        <hr className="border-slate-200" />

        <ul className="space-y-4 font-medium text-center">
          <li>
            <a href="/me" className="font-medium">
              <Icon className="mr-1.5">
                <User />
              </Icon>
              {t('frontend:log_in_to_campaign')}
            </a>
          </li>
          {!charity && (
            <li>
              <a
                href="https://supporta.cc/nl/goed-doel-aandragen"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon className="mr-1.5">
                  <ExternalLink />
                </Icon>
                {t('frontend:charity_missing')}
              </a>
            </li>
          )}
          <li>
            <a
              href="https://supporta.cc/nl/helpdesk"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon className="mr-1.5">
                <HelpCircle />
              </Icon>
              {t('frontend:frequently_asked_questions')}
            </a>
          </li>
        </ul>
      </Container>
    </>
  );
}

SelectCharityPage.layout = (page: ReactNode) => <Layout>{page}</Layout>;

const CharityCard = ({
  charity,
  event,
}: {
  charity: Charity,
  event: Event | null,
}) => {
  const { routes } = useRouter();

  return (
    <Card
      spacing="xs"
      link={{
        href: `${routes.select_charity_page({ charityId: charity.id, eventId: event?.id })}`,
        ariaLabel: charity.title,
      }}
      border
      className="flex items-center gap-4"
    >
      <div className="flex items-center justify-center flex-shrink-0 w-16 h-16 p-2 border rounded">
        {charity.logo_url && (
          <img src={charity.logo_url} alt={charity.title} className="block object-contain w-full h-full" />
        )}
        {!charity.logo_url && (
          <span className="block w-10 h-10 rounded-full leading-[2.5rem] text-center bg-blue-300 text-white">
            {charity.title.slice(0, 1)}
          </span>
        )}
      </div>
      <div className="min-w-0">
        <div className="flex items-center gap-2">
          <h4 className="overflow-hidden whitespace-nowrap text-ellipsis">
            {charity.title}
          </h4>
          <Icon className="text-slate-400">
            <ChevronRight />
          </Icon>
        </div>
        <div className="w-full text-slate-500 line-clamp-2">
          {charity.description}
        </div>
      </div>
    </Card>
  );
};

const EventCard = ({
  charity,
  event,
}: {
  charity: Charity | null,
  event: Event,
}) => {
  const { routes } = useRouter();

  return (
    <Card
      spacing="xs"
      link={{
        href: `${routes.select_charity_page({ charityId: charity?.id, eventId: event.id })}`,
        ariaLabel: event.title,
      }}
      border
      className="flex items-center gap-4"
    >
      <div className="flex items-center justify-center flex-shrink-0 w-16 h-16 p-2 border rounded">
        {event.logo_url && (
          <img src={event.logo_url} alt={event.title} className="block object-contain w-full h-full" />
        )}
        {!event.logo_url && (
          <span className="block w-10 h-10 rounded-full leading-[2.5rem] text-center bg-blue-300 text-white">
            {event.title.slice(0, 1)}
          </span>
        )}
      </div>
      <div className="min-w-0">
        <div className="flex items-center gap-2">
          <h4 className="overflow-hidden whitespace-nowrap text-ellipsis">
            {event.title}
          </h4>
          <Icon className="text-slate-400">
            <ChevronRight />
          </Icon>
        </div>
      </div>
    </Card>
  );
};
