import { Head } from '@inertiajs/react';
import { ReactNode } from 'react';
import { Link as LinkIcon, Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { useLocale } from '../../Shared/locale';
import { Campaign, Event, Pagination, Project } from '../../Shared/types';
import { Icon } from '../../Shared/UI/Icon';
import { Pager } from '../../Shared/UI/Pager';
import { SearchInput } from '../../Shared/UI/SearchInput';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import CampaignCard from '../Campaign/CampaignCard';
import EventCard from '../Event/EventCard';
import { SharedProps } from '../types';
import { LinkButton } from '../UI/Button';
import Container from '../UI/Container';
import Header from '../UI/Header';
import Layout from '../UI/Layout';
import { NavBar } from '../UI/NavBar';
import PageOfflineWarning from '../UI/PageOfflineWarning';

export interface ProjectPageProps extends SharedProps {
  project: Project & {
    event: Event | null;
    create_campaign_url: string;
  };
  campaigns: Pagination<Campaign & {
    project: Project;
  }>;
}

export default function ProjectPage({
  project,
  campaigns,
  charity,
  auth: { user },
  params,
}: ProjectPageProps) {
  const { t } = useTranslation();
  const { formatCurrency, formatNumber } = useLocale();

  useCharityStyle(charity);

  const imageUrl = project.image_url || charity.image_url;

  return (
    <>
      <Head title={`${project.title} – ${charity.title}`} />

      <NavBar charity={charity} user={user} />

      <Header>
        <Container>
          {!project.visible && (
            <PageOfflineWarning />
          )}

          <div className="flex items-center space-x-4">
            {project.event?.logo_url && (
              <div className="shrink-0">
                <div className="w-[60px] h-[60px] shadow bg-white border rounded-full flex items-center justify-center">
                  <img src={project.event.logo_url} className="block max-h-[48px] max-w-[48px] rounded-full" />
                </div>
              </div>
            )}
            <h1 className="text-2xl">
              {project.title}
            </h1>
          </div>

          {imageUrl && (
            <div className="aspect-video">
              <img src={imageUrl} alt={project.title} className="object-cover w-full h-full rounded-lg" />
            </div>
          )}

          <div className="grid grid-flow-col gap-2 auto-cols-auto">
            <div>
              <div className="text-lg font-medium">
                {formatCurrency(project.funds_raised, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
              </div>
              <span className="text-sm font-medium text-slate-600">
                {t('frontend:raised')}
              </span>
            </div>
            <div>
              <div className="text-lg font-medium">
                {formatNumber(project.campaigns_count)}
              </div>
              <span className="text-sm font-medium text-slate-600">
                {t('frontend:campaigns', { count: project.campaigns_count })}
              </span>
            </div>
          </div>
        </Container>
      </Header>

      <Container>
        {(project.website || project.description || charity.description) && (
          <div className="space-y-4">
            {project.website && (
              <div>
                <a href={project.website} target="_blank" rel="noopener noreferrer">
                  <Icon className="mr-2">
                    <LinkIcon />
                  </Icon>
                  {project.website}
                </a>
              </div>
            )}

            {(project.description || charity.description) && (
              <div className="whitespace-pre-line">
                {project.description || charity.description}
              </div>
            )}
          </div>
        )}

        {project.open && !project.ended && (
          <LinkButton
            href={project.create_campaign_url}
            size="lg"
            className="block w-full !font-bold"
          >
            <Icon className="mr-2">
              <Plus />
            </Icon>
            {t('frontend:start_a_campaign')}
          </LinkButton>
        )}

        <hr className="border-slate-200" />

        <div className="space-y-4">
          <h2 className="text-xl">
            {t('frontend:campaigns')}
          </h2>
          {(params.search || campaigns.meta.last_page > 1) && (
            <div>
              <SearchInput id="search" />
            </div>
          )}
          <div className="space-y-2">
            {campaigns.data.map((campaign) => (
              <CampaignCard charity={charity} campaign={campaign} key={campaign.id}/>
            ))}
            {campaigns.meta.total === 0 && (
              <div className="text-slate-500">
                {t('frontend:no_results')}
              </div>
            )}
          </div>
          {campaigns.meta.last_page > 1 && (
            <Pager data={campaigns} />
          )}
        </div>

        {project.event && (
          <>
            <hr className="border-slate-200" />
            <div className="space-y-4">
              <h2 className="text-xl">
                {t('frontend:event')}
              </h2>
              <EventCard event={project.event} />
            </div>
          </>
        )}
      </Container>
    </>
  );
}

ProjectPage.layout = (page: ReactNode) => <Layout>{page}</Layout>;
